@import '../../../../components/scss/Common';

.summary-module-bar {
  display: flex;
  flex-direction: row;
  align-items: center;

  .summary-icon {
    display: inline-block;
    $size: 20px;
    width: $size;
    height: $size;
    cursor: pointer;
  }

  .status-text {
    margin-left: 4px;
    cursor: pointer;
  }
}


